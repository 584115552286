import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';
import styled, { css, keyframes } from 'styled-components';
import { colors, media, easings, commonKeyframes } from '~/styles';
import { Tag } from '~/types/work';

interface CardProps {
  title: string;
  tags: Tag[];
  client: string;
  thumbnail: any;
  hotspot: any;
  slug: string;
  delay: number;
  onClickTag: (tag: Tag) => void;
  currentTag: Tag | 'ALL';
}

const Card: React.VFC<CardProps> = ({
  title,
  tags,
  client,
  thumbnail,
  hotspot,
  slug,
  delay,
  onClickTag,
  currentTag,
}) => (
  <Wrapper delay={delay}>
    <Inner>
      <ThumbnailWrapper to={`/works/${slug}`}>
        <Thumbnail
          image={thumbnail}
          alt="thumbnail"
          style={
            hotspot &&
            ({
              '--image-position': `${100 * hotspot.y}%`,
            } as React.CSSProperties)
          }
        />
      </ThumbnailWrapper>
      <TextWrapper>
        <Title>
          <UnderLineLink to={`/works/${slug}`} state={{ tag: currentTag }}>
            {title}
          </UnderLineLink>
        </Title>
        <Caption>
          <TagWrapper>
            {tags.map((tag, i) => (
              <>
                {i > 0 && <span>/</span>}
                <TagButton
                  key={tag as string}
                  active={tag === currentTag}
                  onClick={() => {
                    onClickTag(tag);
                  }}
                >
                  {tag}
                </TagButton>
              </>
            ))}
          </TagWrapper>
          <Client>{client}</Client>
        </Caption>
      </TextWrapper>
    </Inner>
  </Wrapper>
);

const cardIn = keyframes`
  from {
    padding-top: calc(56.25% + 160px);
  }
  to {
    padding-top: calc(56.25% + 80px);
  }
`;

const Wrapper = styled.li<{ delay: number }>`
  position: relative;
  display: inline-block;
  opacity: 0;
  transform: translateY(80px);
  animation: ${commonKeyframes.fadeIn} 0.75s ${easings.easeOutCubic} forwards,
    ${commonKeyframes.slideIn} 0.75s ${easings.easeOutCubic} forwards;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: calc(56.25% + 80px);
  }

  ${({ delay }) => css`
    animation-delay: ${delay}s;
  `}
`;

const Inner = styled.div`
  position: absolute;
  inset: auto 0 0;
  display: grid;
  grid-gap: 16px;
  align-content: end;
  width: 100%;
  margin: 0;
`;

const ThumbnailWrapper = styled(Link)`
  position: relative;
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }
`;

const Thumbnail = styled(GatsbyImage)`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  transition: transform 0.6s ${easings.easeOutCubic};

  img {
    object-position: center var(--image-position) !important;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.5) inset;
    opacity: 1;
    transition: opacity 0.6s ${easings.easeOutCubic};
  }

  &:hover {
    transition-duration: 0.4s;
    transform: scale(1.02);

    &::after {
      transition-duration: 0.3s;
      opacity: 0;
    }
  }
`;

const TextWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  align-items: start;

  ${media.smDown} {
    grid-gap: 8px;
  }
`;

const Title = styled.h3`
  font-weight: normal;
  line-height: 1.5;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${media.smDown} {
    font-size: 16px;
  }
`;

const UnderLineLink = styled(Link)`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: auto 0 0;
    height: 1px;
    width: 0;
    background-color: ${colors.white};
    transition: width 0.6s ${easings.easeOutCubic};
  }

  &:hover {
    &::before {
      width: 100%;
      transition-duration: 0.3s;
    }
  }
`;

const Caption = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  color: ${colors.lightGray};
  font-size: 12px;
  line-height: 1.2;

  ${media.smDown} {
    font-size: 14px;
  }
`;

const TagWrapper = styled.p`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: start;
`;

const TagButton = styled.button<{ active?: boolean }>`
  transition: color 0.6s ${easings.easeOutCubic};

  &:hover {
    transition-duration: 0.15s;
    color: ${colors.white};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${colors.white};
    `}
`;

const Client = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default Card;
