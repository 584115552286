import React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'gatsby-plugin-react-i18next';
import { media, commonKeyframes, easings, TextHalfWidth } from '~/styles';
import TextBreaks from '~/components/TextBreaks';

interface TitleProps {
  title?: string;
  className?: string;
  byKey?: boolean;
  isIntersection: boolean;
}

const Title = React.forwardRef<HTMLDivElement, TitleProps>(
  ({ title, className, byKey, isIntersection }, ref) => (
    <Wrapper className={className} ref={ref}>
      <TitleWrapper>
        <TitleText isIntersection={isIntersection}>
          {byKey ? (
            <Trans
              i18nKey={title}
              components={[...TextBreaks, <TextHalfWidth />]}
            />
          ) : (
            `${title}`
          )}
        </TitleText>
      </TitleWrapper>
    </Wrapper>
  ),
);

const Wrapper = styled.div<{ top?: boolean }>`
  display: grid;
  grid-gap: 80px;
  justify-items: center;
  text-align: center;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 1;

  ${media.mdDown} {
    grid-gap: 60px;
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
`;

const TitleText = styled.p<{ isIntersection: boolean }>`
  font-size: 60px;
  line-height: 80px;
  white-space: pre-wrap;
  opacity: 0;
  transform: translateY(100%);

  ${media.md} {
    font-size: 40px;
    line-height: 1.6;
  }

  ${media.smDown} {
    font-size: 24px;
    line-height: 1.6;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.45s ${easings.easeOutCubic} 0.3s
          forwards,
        ${commonKeyframes.slideIn} 0.45s ${easings.easeOutCubic} 0.3s forwards;
    `}
`;

export default Title;
