import React, { useState, useMemo, useCallback } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { graphql, PageProps } from 'gatsby';
import styled, { css } from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import Title from '~/components/Title';
import {
  NoImgTopSection,
  Section,
  colors,
  media,
  commonKeyframes,
  easings,
} from '~/styles';
import Card from '~/components/works/Card';
import type { Tag } from '~/types/work';
import { sleep } from '~/utils';

const WorksPage: React.VFC<
  PageProps<
    GatsbyTypes.WorksPageQuery,
    Record<string, unknown>,
    { tag: Tag | undefined }
  >
> = ({ data: { rawWorks }, location }) => {
  const { t, i18n } = useTranslation();
  const [tag, setTag] = useState<Tag | 'ALL'>(location.state?.tag ?? 'ALL');
  const [waitAnim, setWaitAnim] = useState(false);

  const works = useMemo(
    () =>
      rawWorks.nodes.map((val) => {
        const articleJa = {
          client: val.client,
          title: val.title,
          locale: val.i18n_lang,
        };

        const workEn =
          val.i18n_refs && val.i18n_refs?.length > 0
            ? val.i18n_refs[0]?.ref
            : null;

        const articleEn = workEn
          ? {
              client: workEn.client,
              title: workEn.title,
              locale: workEn.i18n_lang,
            }
          : null;

        return {
          slug: val.slug?.current,
          tags: val.tags as Tag[],
          thumbnail: val.thumbnail?.asset?.gatsbyImageData,
          hotspot: val.thumbnail?.hotspot,
          localizedItem:
            i18n.language === 'en' && articleEn ? articleEn : articleJa,
        };
      }),
    [i18n.language, rawWorks],
  );

  const selectedWorks = useMemo(
    () => works.filter((work) => tag === 'ALL' || work.tags?.includes(tag)),
    [works, tag],
  );

  const handleClickTag = useCallback((val: Tag | 'ALL') => {
    const animation = async () => {
      setWaitAnim(true);
      setTag(val);
      await sleep(1);
      setWaitAnim(false);
    };

    void animation();
  }, []);

  return (
    <>
      <SEO title="Works" />
      <Layout>
        <NoImgTopSection>
          <Title title="works.title" byKey isIntersection />
        </NoImgTopSection>
        <WorksSection>
          <TagSelector>
            <Button
              active={tag === 'ALL'}
              onClick={() => handleClickTag('ALL')}
            >
              {t('common.tags.all')}
            </Button>
            <Divider />
            <ButtonWrapper>
              <Button
                active={tag === 'MOVIE'}
                onClick={() => handleClickTag('MOVIE')}
              >
                {t('common.tags.movie')}
              </Button>
              <Button
                active={tag === 'GAME'}
                onClick={() => handleClickTag('GAME')}
              >
                {t('common.tags.game')}
              </Button>
              <Button
                active={tag === 'CM'}
                onClick={() => handleClickTag('CM')}
              >
                {t('common.tags.cm')}
              </Button>
              <Button
                active={tag === 'EVENT'}
                onClick={() => handleClickTag('EVENT')}
              >
                {t('common.tags.event')}
              </Button>
              <Button
                active={tag === 'TV'}
                onClick={() => handleClickTag('TV')}
              >
                {t('common.tags.tv')}
              </Button>
            </ButtonWrapper>
          </TagSelector>
          <List>
            {selectedWorks.map((work, index) => {
              if (waitAnim) {
                return null;
              }

              return (
                <Card
                  slug={work.slug ?? ''}
                  title={work.localizedItem?.title ?? ''}
                  tags={work.tags ?? []}
                  client={work.localizedItem?.client ?? ''}
                  thumbnail={work.thumbnail}
                  hotspot={work.hotspot}
                  onClickTag={handleClickTag}
                  currentTag={tag}
                  delay={index * 0.075}
                  key={work.slug ?? ''}
                />
              );
            })}
          </List>
        </WorksSection>
      </Layout>
    </>
  );
};
const WorksSection = styled(Section)`
  max-width: none;
`;

const TagSelector = styled.div`
  display: grid;
  grid-template-columns: 1fr auto repeat(5, 1fr);
  grid-gap: 16px;
  opacity: 0.5;
  opacity: 0;
  transform: translateY(32px);

  animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} 0.6s
      forwards,
    ${commonKeyframes.slideIn} 0.6s ${easings.easeOutCubic} 0.6s forwards;

  ${media.mdDown} {
    grid-template-columns: 100%;
    grid-gap: 16px;
    width: 100%;
    justify-items: start;
  }
`;

const ButtonWrapper = styled.div`
  display: contents;

  ${media.mdDown} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    width: 100%;
  }
`;

const Button = styled.button<{ active?: boolean }>`
  width: 100px;
  color: ${colors.lightGray};
  border-radius: 999px;
  border: 1px solid;
  border-color: ${colors.lightGray};
  font-size: 16px;
  padding: 6px 0;
  transition: color 0.6s ${easings.easeOutCubic},
    border-color 0.6s ${easings.easeOutCubic};

  ${media.mdDown} {
    width: 100%;
    font-size: 14px;
  }

  &:hover {
    transition-duration: 0.15s, 0.15s;
    color: ${colors.white};
    border-color: ${colors.white};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${colors.white};
      border-color: ${colors.white};
    `}
`;

const Divider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${colors.white};

  ${media.mdDown} {
    display: none;
  }
`;

const List = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px;
  --content-width: 33%;

  opacity: 0;
  transform: translateY(32px);

  animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic} 1.05s
      forwards,
    ${commonKeyframes.slideIn} 0.6s ${easings.easeOutCubic} 1.05s forwards;

  & > * {
    width: var(--content-width);
  }

  &:after {
    content: '';
    display: block;
    width: var(--content-width);
  }

  ${media.mdDown} {
    --content-width: 49%;
  }

  ${media.smDown} {
    --content-width: 100%;
  }
`;

export default WorksPage;

export const query = graphql`
  query WorksPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    rawWorks: allSanityWork(
      filter: { i18n_lang: { eq: "ja" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        id
        i18n_refs {
          ref {
            ... on SanityWork {
              id
              i18n_lang
              credits {
                position
                name
              }
              link
              role
              tags
              title
              thumbnail {
                asset {
                  gatsbyImageData(layout: FULL_WIDTH, fit: FILL)
                }
                hotspot {
                  x
                  y
                }
              }
              client
            }
          }
        }
        link
        role
        slug {
          current
        }
        tags
        title
        thumbnail {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, fit: FILL)
          }
          hotspot {
            x
            y
          }
        }
        i18n_lang
        credits {
          name
          position
        }
        client
      }
    }
  }
`;
